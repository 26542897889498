<template>
  <section>
    <section class="margin-top-2-rem">
      <b-field>
        <b-datepicker
          v-model="dateRange"
          placeholder="Select dates"
          icon="calendar-alt"
          icon-pack="fas"
          :date-formatter="dateFormatter"
          :max-date="maxDate"
          range
          class="is-primary-color"
        />
      </b-field>
    </section>

    <section class="margin-top-2-rem">
      <h2 class="title">
        Calls by Connectors
      </h2>
      <PieChart
        :series="charts.connectors.series"
        value-field="value"
        category-field="kind"
        :is-donut="charts.connectors.isDonut"
        :is-half="charts.connectors.isHalf"
      />
    </section>
    <section class="columns margin-top-2-rem">
      <section class="column is-one-half">
        <h2 class="title">
          Calls by Users
        </h2>
        <PieChart
          :series="charts.users.series"
          value-field="value"
          category-field="name"
          :is-donut="charts.users.isDonut"
          :is-half="charts.users.isHalf"
        />
      </section>
      <section class="column">
        <h2 class="title">
          API Status
        </h2>
        <PieChart
          :series="charts.apiStatus.series"
          value-field="value"
          category-field="status"
          :is-donut="charts.apiStatus.isDonut"
          :is-half="charts.apiStatus.isHalf"
        />
      </section>
    </section>
    <section class="margin-top-2-rem">
      <h2 class="title">
        API requests over time
      </h2>
      <LineChart
        :series="charts.overTime.series"
        y-axis-title="API Requests"
        x-axis-title="Date"
      />
    </section>
    <article class="message is-small is-info margin-top-2-rem">
      <div class="message-body">
        The data for these charts are not provided in real-time and update every three hours
      </div>
    </article>
  </section>
</template>

<script>
import moment from 'moment';
import LineChart from '../components/charts/LineChart';
import PieChart from '../components/charts/PieChart';
// eslint-disable-next-line import/no-cycle
import StatsAPI from '../api/modules/Stats';

export default {
  name: 'PageStats',
  components: {
    LineChart,
    PieChart
  },
  data() {
    return {
      maxDate: moment().toDate(),
      dateRange: [
        moment().subtract(28, 'days').toDate(),
        moment().toDate(),
      ],
      charts: {
        connectors: {
          isDonut: true,
          isHalf: true,
          series: []
        },
        users: {
          isDonut: true,
          isHalf: true,
          series: []
        },
        apiStatus: {
          isDonut: true,
          isHalf: true,
          series: []
        },
        overTime: {
          series: []
        },
      },
    };
  },
  watch: {
    dateRange() {
      this.getStats();
    },
  },
  mounted() {
    this.getStats();
  },
  methods: {
    dateFormatter: (dates) => {
      return `${dates[0].toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})} - ${dates[1].toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}`;
    },
    getStats() {
      this.setAppLoadingState(true);

      StatsAPI.getChartStats(
        moment(this.dateRange[0]).format('X'),
        moment(this.dateRange[1]).format('X')
      ).then(res => {
        this.setAppLoadingState(false);

        if (String(res.data.status) !== 'success') {
          this.$buefy.toast.open({
            message: 'Could not load statistics, please try again',
            type: 'is-danger',
          });
          return;
        }

        this.charts.connectors.series = res.data.connectors;
        this.charts.users.series = res.data.users;
        this.charts.apiStatus.series = res.data.apiStatus;
        this.charts.overTime.series = res.data.overTime;
      });
    },
  }
};
</script>

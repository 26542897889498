<template>
  <div>
    <div
      ref="chart"
      class="am-chart pie-chart"
    />
    <p
      v-if="data.isLoading"
      class="is-primary-color"
    >
      <b-icon
        pack="fas"
        icon="sync"
        size="x-large"
        custom-class="fa-spin"
      /><br>
      Chart data loading
    </p>
    <p
      v-else-if="series.length === 0"
      class="has-text-centered is-warning-color"
    >
      <b>No data for chart</b>
    </p>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { mapState } from 'vuex';
import ConfigHelper from '../../services/configHelper';

am4core.useTheme(am4themes_animated);
ConfigHelper.getConfigValue('AMCHART_LICENSE').then(licence => am4core.addLicense(licence));

export default {
  name: 'PieChart',
  props: {
    series: {
      type: Array,
      required: true,
    },
    valueField: {
      type: String,
      required: true,
    },
    categoryField: {
      type: String,
      required: true,
    },
    isDonut: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHalf: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    ...mapState('app', ['data']),
  },
  watch: {
    series() {
      if (this.chart) {
        this.chart.data = this.series;
      } else {
        this.createChart();
      }
    },
  },
  mounted() {
    this.createChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    createChart() {
      if (this.series.length === 0) {
        return;
      }

      this.chart = am4core.create(this.$refs.chart, am4charts.PieChart);
      this.chart.data = this.series;

      const pieSeries = this.chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = this.valueField;
      pieSeries.dataFields.category = this.categoryField;
      pieSeries.labels.template.disabled = true;

      const colors = this.getFrontEndSkinSeriesColors();
      if (colors) {
        const colorsList = [];
        for (const color of colors) {
          colorsList.push(am4core.color(color));
        }

        pieSeries.colors.list = colorsList;
      }

      if (this.isHalf) {
        this.chart.startAngle = 180;
        this.chart.endAngle = 360;
      }

      if (this.isDonut) {
        this.chart.innerRadius = am4core.percent(40);
      }

      if (this.hasLegend) {
        this.chart.legend = new am4charts.Legend();

        this.chart.legend.itemContainers.template.paddingTop = 2;
        this.chart.legend.itemContainers.template.paddingBottom = 2;

        this.chart.legend.position = "right";
        this.chart.legend.itemContainers.template.tooltipText = "{category}";
        this.chart.legend.scrollable = true;

        this.chart.legend.valueLabels.template.align = "left";
        this.chart.legend.valueLabels.template.textAlign = "end";
      }
    },
  }
};
</script>

import store from '../store/index';

class ConfigHelper {
  static getConfigValue(configValue, forceRetrieval = false) {
    const config = store.getters['connectors/getConfig'];
    if (Object.keys(config).length === 0 || forceRetrieval) {
      return new Promise(resolve => resolve(store.dispatch('connectors/pullConfig').then(serverConfig => serverConfig[configValue])));
    }
    if (Object.prototype.hasOwnProperty.call(config, configValue)) {
      return new Promise(resolve => resolve(config[configValue]));
    }

    throw new Error(`Config value does not exist: ${configValue} (${JSON.stringify(config)})`);
  }
}

export default ConfigHelper;

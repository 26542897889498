<template>
  <div>
    <div
      ref="chart"
      class="am-chart line-chart"
    />
    <p
      v-if="data.isLoading"
      class="has-text-centered is-primary-color"
    >
      <b-icon
        pack="fas"
        icon="sync"
        size="x-large"
        custom-class="fa-spin"
      /><br>
      Chart data loading
    </p>
    <p
      v-else-if="series.length === 0"
      class="has-text-centered is-warning-color"
    >
      <b>No data for chart</b>
    </p>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { mapState } from 'vuex';
import ConfigHelper from '../../services/configHelper';

am4core.useTheme(am4themes_animated);
ConfigHelper.getConfigValue('AMCHART_LICENSE').then(licence => am4core.addLicense(licence));

export default {
  name: 'LineChart',
  props: {
    series: {
      type: Array,
      required: true,
    },
    xAxisTitle: {
      type: String,
      required: false,
      default: '',
    },
    yAxisTitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    ...mapState('app', ['data']),
  },
  watch: {
    series() {
      if (this.chart) {
        this.chart.data = this.series;
      } else {
        this.createChart();
      }
    },
  },
  mounted() {
    this.createChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    createChart() {
      if (this.series.length === 0) {
        return;
      }

      this.chart = am4core.create(this.$refs.chart, am4charts.XYChart);

      const colors = this.getFrontEndSkinSeriesColors();
      if (colors) {
        const colorsList = [];
        for (const color of colors) {
          colorsList.push(am4core.color(color));
        }

        this.chart.colors.list = colorsList;
      }

      this.chart.paddingRight = 20;

      this.chart.data = this.series;

      const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dateFormats.setKey('day', 'ddt MMM');
      dateAxis.periodChangeDateFormats.setKey('day', 'ddt MMM');

      if (this.xAxisTitle) {
        dateAxis.title.text = this.xAxisTitle;
      }

      const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      if (this.yAxisTitle) {
        valueAxis.title.text = this.yAxisTitle;
      }

      // we only deal with integers, so remove anything that's a decimal
      valueAxis.renderer.labels.template.adapter.add("text", function(text) {
        return typeof text !== "undefined" && text.match(/\./) ? "" : text;
      });
      valueAxis.min = 0;

      const series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";
      series.fill = am4core.color('#2970a3');
      series.minBulletDistance = 15;
      series.strokeWidth = 2;

      // draw a bullet point for each data point
      const bullet = new am4charts.CircleBullet();
      bullet.circle.radius = 5;
      series.bullets.push(bullet);

      this.chart.cursor = new am4charts.XYCursor();
    },
  }
};
</script>

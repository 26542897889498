import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to stats for the charts
 */
const StatsAPI = {
  getChartStats(from, to) {
    return axiosClient
      .get(`/api/team/stats?from=${from}&to=${to}`);
  },
};

export default StatsAPI;
